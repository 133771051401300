import Cookies from 'js-cookie';

const getBaseUrl = () => {
    const env = process.env.NODE_ENV
    if(env === 'production') {
        return ''
    } else {
        return 'http://localhost:8000'
    }
}


/////////////// 인증관련 API //////////////////
const getTokens = (type) => {
    if (type === 'refresh') {
        const refreshToken = localStorage.getItem('refresh-token');
        return {'refresh-token': refreshToken};
    } else if (type === 'access') {
        const accessToken = localStorage.getItem('access-token');
        return {'access-token': accessToken};
    }
}

const getRefresh = async () => {
    const refreshToken = getTokens("refresh");

    const apiUrl = `${getBaseUrl()}/api/auth/refresh`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken['refresh-token']}`
            },
        });
        const data = await response.json();

        localStorage.setItem('access-token', response.headers.get('access-token'));
        localStorage.setItem('refresh-token', response.headers.get('refresh-token'));

        return data;
    } catch (error) {
        throw error;
    }
};

const postSignIn = async (username, password) => {
    const apiUrl = `${getBaseUrl()}/api/auth/sign_in`;

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_name: username,
                user_password: password
            }),
            credentials: 'include'
        });
        const data = await response.json();

        localStorage.setItem('access-token', response.headers.get('access-token'));
        localStorage.setItem('refresh-token', response.headers.get('refresh-token'));
        
        return data;
    } catch (error) {
        throw error;
    }
};
//////////////// 인증관련 API 끝 //////////////////

/////////////// ChatbotPage /////////////////////
const postSTT = async (audioBlob, requestData) => {
    const apiUrl = `${getBaseUrl()}/api/stt/audio_to_text`;

    if (!(audioBlob instanceof Blob)) {
        throw new Error("audioBlob is not a Blob instance");
    }

    const formData = new FormData();
    formData.append("file", audioBlob);
    formData.append("item", JSON.stringify(requestData));


    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: formData
        });

        // response가 올바르게 수신되었는지 확인
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};



const getDomains = async () => {
    // const apiUrl = `${config.config.base_url}/api/domains`;
    const apiUrl = `${getBaseUrl()}/api/domains`;

    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};


const getTemplates = async () => {
    const apiUrl = `${getBaseUrl()}/api/doc/templates`;

    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const postRun = async function* (inputQuery, isBlogChecked, isNewsChecked, searchType) {
    
    const apiUrl = `${getBaseUrl()}/api/run`;
    const accessToken = getTokens('access');
    
    const headers = {
        'Content-Type': 'application/json'
    };
    
    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            query: inputQuery,
            blog_yn: isBlogChecked,
            news_yn: isNewsChecked,
            search_type: searchType
        }),
        credentials: 'include'
    });

    console.log(response)

    let reader = response.body.getReader();
    let decoder = new TextDecoder('utf-8');
    let buffer = '';

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        while (true) {
            const newlineIndex = buffer.indexOf('\n');
            if (newlineIndex === -1) break;

            const line = buffer.slice(0, newlineIndex);
            buffer = buffer.slice(newlineIndex + 1);

            if (line.trim()) {
                try {
                    let token = JSON.parse(line);
                    // console.log(token);
                    yield token;
                } catch (e) {
                    console.error('Error parsing JSON : ', e)
                }                    
            }
        }
    }
};


const postChunks = async (queryResponses) => {
    const apiUrl = `${getBaseUrl()}/api/get_chunks`;

    const chunkIds = queryResponses.chunk_ids || [];
    const fileIds = queryResponses.file_ids || [];

    if (!Array.isArray(chunkIds) || !Array.isArray(fileIds) || chunkIds.length !== fileIds.length) {
        throw new Error('chunk_ids and file_ids must be arrays of the same length');
    }
    
    let chunkRequestData = chunkIds.map((chunk_id, index) => {
        return {
            file_ids: fileIds[index],
            chunk_ids: chunk_id
        };
    });

    chunkRequestData = {"query": chunkRequestData};

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(chunkRequestData),
            credentials: 'include'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const getDownloadFile = async (fileId) => {

    const apiUrl = `${getBaseUrl()}/api/download_file?file_id=${fileId}`;
    
    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            credentials: 'include'
        })
    
        const blob = await response.blob();
        const text = await blob.text(); // Get the text content of the blob
            
        // 헤더를 직접 읽기
        const encodedFileName = response.headers.get('Content-Disposition');
        console.log('Content-Disposition:', encodedFileName); 
        if (!encodedFileName) {
            throw new Error('Content-Disposition header is missing');
        }
    
        const fileName = decodeURIComponent(encodedFileName);
        console.log("fileName", fileName);
    
        if (blob) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName; // You can use the actual filename if available
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
    
        // Return both the text content and the blob
        return { success: true, content: text, blob: blob };

    } catch (error) {
        console.error('Error downloading the document:', error);
        throw error;
    }
}

/////////////// ChatbotPage 끝 /////////////////////

/////////////// RagPage /////////////////////
const getRags = async (activedDomainIndex, inputKeyword) => {
    const accessToken = getTokens("access");

    const apiUrl = `${getBaseUrl()}/api/rag?usage_id=${activedDomainIndex}&keyword=${encodeURIComponent(inputKeyword)}`;

    try {
        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${accessToken['access-token']}`
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const putRagOneRow = async (drawerValue) => {
    const accessToken = getTokens("access");

    const apiUrl = `${getBaseUrl()}/api/rag`;

    if (drawerValue.file_outline === null) {
        drawerValue.file_outline = '';
    }
    
    try {
        const response = await fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken['access-token']}`
            },
            
            body: JSON.stringify({
                "file_id": drawerValue.file_id,
                "file_name": drawerValue.file_name,
                "doc_outline": drawerValue.file_outline
            }),
        });

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const deleteRagOneRow = async (drawerValue) => {
    const accessToken = getTokens("access");

    const apiUrl = `${getBaseUrl()}/api/rag?file_id=${drawerValue.file_id}`;

    try {
        const response = await fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken['access-token']}`
            },
        });

        const data = await response.json();
        return data;

    } catch (error) {
        throw error;
    }
};

const postRagOneRow = async (modalValue) => {
    const accessToken = getTokens("access");

    const apiUrl = `${getBaseUrl()}/api/rag`;

    const formData = new FormData();

    formData.append('usage_id', modalValue.categoryId);
    formData.append('file_name', modalValue.fileName);
    formData.append('usage_name', modalValue.categoryName);
    formData.append('file', modalValue.file);
    // formData.append('doc_outline', modalValue.fileOutline);
    formData.append('is_raptor', modalValue.isRaptor);
    formData.append('mode', "upload");
    formData.forEach((value, key) => {
        console.log(`${key} : ${value}`)
    });


    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken['access-token']}`
            },
            body: formData
        });

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};
/////////////// RagPage 끝 /////////////////////

/////////////// Playground Page /////////////////classification
const postMultitaskSummaryDocuments = async(userPrompt, documentGuideQuery, userFile, isDocumentSummaryTitleFixed) => {
    let fileList = []
    let formData = new FormData();

    if (!isDocumentSummaryTitleFixed && !userPrompt) {
        throw new Error('문서 제목을 입력해 주세요.')
    }

    const apiUrl = `${getBaseUrl()}/api/multitask/create_report`;
    if (isDocumentSummaryTitleFixed) {
        userPrompt = '';
    }
    userFile.map((file)=>{
        fileList.push(file.file);
    })

    for (let i = 0; i < fileList.length; i++) {
        formData.append('files', fileList[i]);
    }
    formData.append('generate_title_with_ai', isDocumentSummaryTitleFixed);
    formData.append('report_title', userPrompt);
    formData.append('documentGuideQuery', documentGuideQuery);

    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
        controller.abort();
    }, 1805000); // 30분 5초 후 요청 취소

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: formData,
            signal: controller.signal, // AbortController의 signal 추가
        });
        clearTimeout(timeoutId); // 성공적으로 응답을 받으면 타임아웃 해제
        const data = await response.json();
        return data;
    } catch (error) {
        if (error.name === 'AbortError') {
            console.log('요청이 타임아웃되었습니다.');
            console.log(error);
        } else {
            console.log(error);
        }
        throw error;
    }
}

async function* postMultitaskChat(userPrompt, thisChatThreadId) {

    const apiUrl = `${getBaseUrl()}/api/multitask/chat`;

    const headers = {
        'Content-Type': 'application/json'
    };
    const accessToken = getTokens("access");

    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }

    if (thisChatThreadId !== '') {
        Cookies.set('chat_thread', thisChatThreadId, { expires: 365 });
    }

    const response = await fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify({
            query: userPrompt,
            raptor_type: "collapsed"
        }),
        headers: headers,
        credentials: 'include'
    });

    let reader = response.body.getReader();
    let decoder = new TextDecoder('utf-8');
    let buffer = '';

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        while (true) {
            const newlineIndex = buffer.indexOf('\n');
            if (newlineIndex === -1) break;

            const line = buffer.slice(0, newlineIndex);
            buffer = buffer.slice(newlineIndex + 1);

            if (line.trim()) {
                try {
                    let token = JSON.parse(line);
                    yield token;
                } catch (e) {
                    console.error('Error parsing JSON:', e);
                }
            }
        }
    }
}
const reviseRequest = async (documentPostGuideQuery, documentSummaryResponse) => {
    if (!documentPostGuideQuery) {
        alert('문서 수정 가이드를 입력해주세요.');
        return;
    }

    try {
        // 요청 시작
        const apiUrl = `${getBaseUrl()}/api/multitask/revise`;
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({
                guide: documentPostGuideQuery,
                title: documentSummaryResponse.report_title,
                content: documentSummaryResponse.markdown_content,
            }),
            headers: headers,
        });

        // 응답 처리
        if (response.ok) {
            const data = await response.json();
            console.log('Response:', data);
            return data;
        } else {
            console.error('Request failed:', response.statusText);
            alert('요청이 실패했습니다. 다시 시도해주세요.');
            return null; // 에러 발생 시 null 반환
        }
    } catch (error) {
        console.error('Error:', error);
        alert('오류가 발생했습니다. 네트워크 상태를 확인하세요.');
        return null; // 에러 발생 시 null 반환
    }
};

const postMultitaskDownloadReport = async (reportTitle, markdownContent) => {
    const apiUrl = `${getBaseUrl()}/api/multitask/download_report`;
    const headers = {
        'Content-Type': 'application/json'
    };

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({
                report_title: reportTitle,
                markdown_content: markdownContent,
                file_type: "docx"  
            }),
            headers: headers,
        });
        // if (!response.ok) {
        //     throw new Error(`HTTP error! status: ${response.status}`);
        // }

        const blob = await response.blob();
        const text = await blob.text(); // Get the text content of the blob
        
         // 헤더를 직접 읽기
        const encodedFileName = response.headers.get('Content-Disposition');
        console.log('Content-Disposition:', encodedFileName); 
        if (!encodedFileName) {
            throw new Error('Content-Disposition header is missing');
        }

        const fileName = decodeURIComponent(encodedFileName);
        console.log("fileName", fileName);

        if (blob) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName; // You can use the actual filename if available
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }

        // Return both the text content and the blob
        return { success: true, content: text, blob: blob };
    } catch (error) {
        // console.error('Error downloading the document:', error);
        throw error;
    }

}

const getMultitaskChatHistory = async() => {
    const apiUrl = `${getBaseUrl()}/api/multitask/chat_history`;

    const accessToken = getTokens("access");

    const headers = {}

    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: headers,
            credentials: 'include'
        });
        const data = await response.json();
        return data;

    } catch (error) {
        throw error;
    }
}

const getMultitaskThreadHistory = async(thisChatThreadId) => {
    const apiUrl = `${getBaseUrl()}/api/multitask/chat_thread`;

    const accessToken = getTokens("access");

    const headers = {}

    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }

    if (thisChatThreadId !== '') {
        Cookies.set('chat_thread', thisChatThreadId, { expires: 365 });
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: headers,
            credentials: 'include'
        });
        const data = await response.json();
        console.log(response);
        console.log(data)
        return data;

    } catch (error) {
        throw error;
    }
}


const putMultitaskChatFeedback = async (chatUUID, feedback, feedbackDetail) => {
    const apiUrl = `${getBaseUrl()}/api/multitask/chat_feedback`;

    const accessToken = getTokens("access");

    const feedbackData = {
        chat_uuid: chatUUID,
        feedback: feedback,
        feedback_detail: feedbackDetail
    }

    // console.log(feedbackData);

    const headers = {
        'Content-Type': 'application/json'
    };

    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(feedbackData),
            credentials: 'include'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}

const getMenu = async () => {
    const apiUrl = `${getBaseUrl()}/api/menu`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
        });
        const data = await response.json();
        return data;

    } catch (error) {
        throw error;
    }
}

/////////////// Playground Page 끝 /////////////////

export {
    getDomains,
    postRun,
    postChunks,
    getDownloadFile,
    postSTT,
    getRags,
    getTemplates,
    putRagOneRow,
    deleteRagOneRow,
    postRagOneRow,
    postSignIn,
    getRefresh,
    postMultitaskChat,
    getMultitaskChatHistory,
    getMultitaskThreadHistory,
    postMultitaskSummaryDocuments,
    postMultitaskDownloadReport,
    putMultitaskChatFeedback,
    getMenu,
    reviseRequest
};
